












import Button from "@/components/Button.vue";
import { LevelData, LevelDataItem } from "@/views/Game.vue";
import gsap from "gsap";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
	components:{
		Button,
	}
})
export default class Hints extends Vue {

	@Prop()
	public data:LevelData;

	public getClasses(item:LevelDataItem):string[] {
		let res = ["item"];
		if(item.f == true) res.push("found");
		return res;
	}

	public mounted():void {
		
	}

	public beforeDestroy():void {
		
	}

	public onClose():void {
		let bt = (this.$refs.closeBt as Vue).$el;
		gsap.set(bt, {scaleX:1, scaleY:1, rotate:0, opacity:1});
		gsap.from(bt, {duration:1, scaleX:1.2, ease:"elastic.out(1.5,.25)"});
		gsap.from(bt, {duration:1, scaleY:1.2, ease:"elastic.out(1.5,.25)"});
		this.$emit("close");
	}

}
